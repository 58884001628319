.w-130 {
  width: 130px;
}

.MTableHeader-header-13 {
  z-index: 0 !important;
}

.input-chatbot::placeholder {
  color: black !important;
  font-weight: 400 !important;
}
.MuiAccordionSummary-content {
  margin: 0 !important;
  display: flex;
  flex-grow: 1;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
