.Login {
  background-image: url("../images//login/fondo.jpg");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.MTableToolbar-root-5 {
  padding-right: 8px;
  background-color: #ff8182 !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgb(255 255 255 / 42%) !important;
  pointer-events: none;
}

.MuiInputBase-root {
  color: white !important;
}

.MuiIconButton-root.Mui-disabled {
  color: white !important;
  background-color: transparent;
}

.MTableHeader-header-13 {
  background-color: #ff8182 !important;
  color: white !important;
  font-weight: 700 !important;
  font-size: 20px !important;
}

.Login__logo {
  width: 30%;
  margin-top: 3%;
}

.d-grid {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 70%;
}

.Login__form {
  width: 30%;
  margin: 0 auto;
}

.btn_login {
  padding: 10px 110px !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}
.btn_login:hover {
  background-color: #341677 !important;
}
.inputLogin {
  background-color: #8573ad !important;
  color: white !important;
  font-weight: 700 !important;
  padding: 10px !important;
  border-radius: 10px !important;
}

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: white !important;
  font-weight: 700;
}
input::-moz-placeholder {
  /* Firefox 19+ */
  color: white !important;
  font-weight: 700;
}
input:-ms-input-placeholder {
  /* IE 10+ */
  color: white !important;
  font-weight: 700;
}
input:-moz-placeholder {
  /* Firefox 18- */
  color: white !important;
  font-weight: 700;
}

.App-logo {
  pointer-events: none;
}
.App-logo2 {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 13s linear;
  }
  .App-logo2 {
    animation: App-logo-spin2 infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes App-logo-spin2 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
